import styled from "styled-components";
import { ISideBarProps } from "../SideBar/Sidebar.structure";

export const Container = styled.div<ISideBarProps>`
  height: 100vh;
  background-color: var(--gray5);
  width: ${(props) => (props.collapse ? "4.1rem" : "13rem")};
  padding-top: 2.3rem;
  padding-bottom: 2rem;
  padding-left: ${(props) => (props.collapse ? "0.6rem" : "1rem")};
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  img {
    max-width: 8rem;
    max-height: 80%;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
    margin-bottom: 1.1rem;
  }
`;

export const Group = styled.div<ISideBarProps>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1rem;
  color: ${(props) => (props.isActive ? "var(--primary)" : "var(--gray1)")};
  padding: 0.5rem;
  overflow: hidden;
  width: 100%;
  transition: all 0.2s ease-in-out;

  span {
    font-size: 0.9rem;
    margin-left: 0.5rem;
    min-width: 4rem;
    gap: 0.5rem;
  }
`;

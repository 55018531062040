import { Container, Group } from "./styles";
import logo from "../../assets/logo.png";
import { IMenusGroup } from "../SideBar/Sidebar.structure";
import * as Icon from "react-icons/fa";
import React from "react";
import CollapsebleMenus from "../CollapsebleMenu";
import { UserContext } from "../../context/UserProvider";

const MenuGroups = ({
  collapse,
  groups,
}: {
  collapse: boolean;
  groups: IMenusGroup[];
}) => {
  const [activeMenu, setActiveMenu] = React.useState<number>(0);
  const {userPermissions} = React.useContext(UserContext)

  const group = React.useMemo(() => {
    return groups?.map((group, index) => {
      const {iconGroup, subMenus, titleGroup} = group;
      const IconComponent = iconGroup ? Icon[iconGroup] : Icon.FaFileExcel;
      if (subMenus.length > 0) {
        return (
          <CollapsebleMenus
            key={index}
            iconGroup={iconGroup}
            groupTitle={titleGroup}
            subMenus={subMenus}
            collapse={collapse}
            isActive={activeMenu === index}
            onClick={() => setActiveMenu(index)}
          />
        );
      }

      if (subMenus.length === 0) {
        return (
          <Group
            key={index}
            isActive={activeMenu === index}
            onClick={() => setActiveMenu(index)}
          >
            <IconComponent />
            <span>{titleGroup}</span>
          </Group>
        );
      }
    });
  }, [groups, collapse, activeMenu,userPermissions]);

  return (
    <Container collapse={collapse}>
      <img src={logo} alt="logo" />
      {group}
    </Container>
  );
};

export default MenuGroups;

export const menus = [
  {
    "titleMenu":"ADM",
    "iconMenu":"FaCrown",
    "related_flag":["Administrador"],
    "groups":[
        {
          "titleGroup":"ADM",
          "iconGroup":"FaUserFriends",
          "subMenus":[
              {
                "titleSubMenu":"Criar grupo de permissões",
                "mandatory_permissions":["Administrador.GrupoDePermissões.edit"],
                "related_permissions":["Administrador.Permissões.edit","Administrador.Permissões.view"],
                "iconSubMenu":"FaUsersCog"
              },
              {
                "titleSubMenu":"Criar perfil",
                "mandatory_permissions":["Administrador.Usuário.edit"],
                "related_permissions":["Administrador.Usuário.edit"],
                "iconSubMenu":"FaUserPlus"
            },
              
              {
                "titleSubMenu":"Gerenciar perfis",
                "mandatory_permissions":[],
                "related_permissions":["Administrador.Usuário.view","Administrador.Usuário.edit"],
                "iconSubMenu":"FaUserCog"
                
              }
          ]
        }
    ]
  },
  {
    "titleMenu":"Fiscal",
    "iconMenu":"FaSearchDollar",
    "related_flag":["Fiscal"],
    "groups":[
        {
          "titleGroup":"Obrigações Acessórias",
          "iconGroup":"FaFileAlt",
          "subMenus":[
              {
                "titleSubMenu":"DIMP",
                "mandatory_permissions":[],
                "related_permissions":[],
                "iconSubMenu":"FaFileInvoiceDollar"
                
              }
          ]
        }
    ]
  },
  {
    "titleMenu":"Compliance",
    "iconMenu":"FaBalanceScale",
    "related_flag":["Compliance"],
    "groups":[
        {
          "titleGroup":"Monitoramento",
          "iconGroup":"FaEye",
          "subMenus":[
              {
                "titleSubMenu":"Alertas",
                "mandatory_permissions":[],
                "related_permissions":["Compliance.Alertas.edit","Compliance.Alertas.view"],
                "iconSubMenu":"FaBell"
              }
          ]
        }
    ]
  }
]
import styled from "styled-components";
import { IHamburguerProps } from "./Hamburguer.structure";

export const Container = styled.div`
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-bottom: 1rem;
  background-color: var(--gray3);
  height: 5.3rem;
`;

export const HamburguerContainer = styled.div`
  width: 1.4rem;
  span {
    height: 3px;
    display: flex;
    background-color: var(--gray1);
    transition: all 0.2s;
    border-radius: 20px;
    transition: all 0.2s ease-in-out;
  }
`;

export const Top = styled.span.attrs({
  className: "top",
})<IHamburguerProps>`
  ${(props) => props.active && `transform: rotate(-50deg) translateX(-5.5px);`}
  ${(props) => props.active && `width: 45%;`}
`;

export const Stuffing = styled.span.attrs({
  className: "stuffing",
})<IHamburguerProps>`
  margin: 5px 0;

  ${(props) =>
    props.active &&
    `
    width: 80%; 
    transform: translateX(-3px);
    }`}
`;

export const Bottom = styled.span.attrs({
  className: "bottom",
})<IHamburguerProps>`
  ${(props) => props.active && `transform: rotate(50deg) translateX(-5.5px);`}
  ${(props) => props.active && `width: 45%;`}
`;

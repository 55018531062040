import {
  CollapsebleMenuTitle,
  CollapsebleMenu,
  CollapsebleMenuItems,
  CollapsebleSubMenuTitle,
} from "./styles";
import * as Icon from "react-icons/fa";
import React from "react";
import { LinksDictionary } from "../../utils/LinksDictionary";
import { IPropsCollabsebleMenu } from "./CollapsebleMenu.structure";
import { UserContext } from "../../context/UserProvider";

const CollapsebleMenus = ({
  iconGroup,
  groupTitle,
  subMenus,
  collapse,
  isActive,
}: IPropsCollabsebleMenu) => {
  const IconComponent = iconGroup ? Icon[iconGroup] : Icon.FaFileExcel;
  const [activeSubMenu, setActiveSubMenu] = React.useState<number>();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [subMenusHeight, setSubMenusHeight] = React.useState<number>(0);
  const subMenusRef = React.useRef<HTMLDivElement>(null);
  const { userPermissions } = React.useContext(UserContext);

  const SubMenus = React.useMemo(() => {
    return subMenus?.map((submenu, index) => {
      const IconSubComponent = submenu.iconSubMenu
        ? Icon[submenu.iconSubMenu]
        : Icon.FaFileExcel;
      const isActive = activeSubMenu === index;
      const hasMandatoryPermissions = submenu.mandatory_permissions && submenu.mandatory_permissions.every(permission => userPermissions.includes(permission));
      const hasOptionalPermissions = submenu.related_permissions && submenu.related_permissions.some(permission => userPermissions.includes(permission));

      if(!hasMandatoryPermissions) return;
      if(!hasOptionalPermissions) return;

      if (index === 0) {
        setSubMenusHeight(0);
      }

      setSubMenusHeight(44 * subMenus.length + 7 * index);

      const redirectTo = () => {
        window.history.replaceState(null,'Page Title',LinksDictionary[submenu.titleSubMenu].path);
      };

      return (
        <CollapsebleSubMenuTitle
          key={index}
          ref={subMenusRef}
          collapse={collapse}
          onClick={() => {
            setActiveSubMenu(index);
            redirectTo();
          }}
          isActive={isActive}
        >
          <IconSubComponent
            color={isActive && "#42C1C7"}
            style={{
              minWidth: "1.5rem",
              marginRight: "0.4rem",
              transition: "all 0.2s ease-in-out",
            }}
          />
          <p>{submenu.titleSubMenu}</p>
        </CollapsebleSubMenuTitle>
      );
    });
  }, [subMenus, collapse,userPermissions, activeSubMenu]);


  return (
    <CollapsebleMenu>
      <CollapsebleMenuTitle
        collapse={collapse}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        isActive={isActive}
        isOpen={isOpen}
        data-testid="collapseble-menu-title"
      >
        <div>
          <IconComponent />
          <span>{groupTitle}</span>
        </div>
        <Icon.FaAngleDown
          style={{
            transform: isOpen ? "rotate(-180deg)" : "rotate(0deg)",
            transition: "all 0.2s ease-in-out",
          }}
        />
      </CollapsebleMenuTitle>
      <CollapsebleMenuItems
        collapse={collapse}
        isOpen={isOpen}
        subMenusHeight={subMenusHeight}
      >
        {SubMenus}
      </CollapsebleMenuItems>
    </CollapsebleMenu>
  );
};

export default CollapsebleMenus;

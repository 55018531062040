import { IMenuButtonProps } from "./MenuButton.structure";
import { Container, Content, ContentLeft, ContentRight } from "./styles";
import * as Icon from "react-icons/fa";

const MenuButton = ({
  title,
  iconMenu,
  collapse,
  isActive,
}: IMenuButtonProps) => {
  const IconComponent = iconMenu ? Icon[iconMenu] : Icon.FaFileExcel;

  return (
    <Container isActive={isActive} collapse={collapse}>
      <Content collapse={collapse}>
        <ContentLeft collapse={collapse}>
          <IconComponent />
        </ContentLeft>
        <ContentRight collapse={collapse}>
          <IconComponent />
          <span>{title}</span>
        </ContentRight>
      </Content>
    </Container>
  );
};

export default MenuButton;

import styled from "styled-components";
import { ISideBarProps } from "../SideBar/Sidebar.structure";

export const Container = styled.div<ISideBarProps>`
  background-color: ${(props) =>
    props.isActive ? "var(--primary)" : "var(--gray5)"};
  color: var(--gray1);
  height: 3.8rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  margin: 0.5rem 15px 0.5rem 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &:hover {
    background-color: ${(props) =>
      props.isActive ? "var(--primary)" : "var(--gray6)"};
    transition: background-color 0.2s ease-in-out;
  }
`;

export const Content = styled.div<ISideBarProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  margin-left: ${(props) => (props.collapse ? "6rem" : "0rem")};
  margin-right: ${(props) => (props.collapse ? "0rem" : "6rem")};
  overflow: hidden;

  span {
    font-size: ${(props) => (props.collapse ? "0rem" : "0.8rem")};
    margin-top: 0.2rem;
  }
`;

export const ContentRight = styled.div<ISideBarProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 3rem;
  margin-left: 4.5rem;
`;

export const ContentLeft = styled.div<ISideBarProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  svg {
    margin-left: ${(props) => (props.collapse ? "1rem" : "0rem")};
    width: 1rem;
  }
`;

import { createGlobalStyle } from "styled-components";

const Globals = createGlobalStyle`

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --primary:#42C1C7;
  --secondary:#521AA3;

  --success:#24A148;
  --error: #DA1E28;

  --gray1:#F4F4F4;
  --gray2:#e0e0e0;
  --gray3:#a8a8a8;
  --gray4:#6f6f6f;
  --gray5:#393939;
  --gray6:#1a1a1a;
}
`;

export default Globals;

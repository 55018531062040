import React from "react";
import { ActiveMenu } from "../utils/LinksDictionary";
import { IMenuContext, IProps, ISelectedMenu } from "./MenuProvider.structure";

export const MenuContext = React.createContext({} as IMenuContext);

const MenuProvider: React.FC<IProps> = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = React.useState({} as ISelectedMenu);

  React.useEffect(() => {
    setSelectedMenu(ActiveMenu[window.location.pathname]);
  }, []);

  const value = React.useMemo(
    () => ({
      selectedMenu,
      setSelectedMenu,
    }),
    [selectedMenu, setSelectedMenu]
  );
  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};

export default MenuProvider;

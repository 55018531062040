import api from "./api";

class PermissionsService {

  async getUserPermissions(id: string) {
    try {
      const { data } = await api.get(`/permissions/${id}`);
      return data;
    } catch (error) {
      return false;
    }
  }
}

export default new PermissionsService();

import React from "react";
import SideBar from "./components/SideBar";
import MenuProvider from "./context/MenuProvider";
import Globals from "./globals";
import { menus } from "./mocks/sideMenu";
import api from "./service/api";
import LoginService from "./service/Login.service";
import Cookies from 'js-cookie';
import UserProvider from "./context/UserProvider";

export default function Root(props) {
  const env_type = process.env.ENV_TYPE;

  const localLogin = React.useCallback(async () => {
    const email = process.env.LOCAL_ENV_USER;
    const password = process.env.LOCAL_ENV_PASSWORD;

    try {
      const localToken = await LoginService.login(
        email,
        password
      );
      const { token, refresh_token, id } = localToken;
      Cookies.set('token', token);
      Cookies.set('id', id);  
      api.defaults.headers.common["Authorization"] = `bearer ${token}`;
      api.defaults.headers.common["refresh_token"] = refresh_token; 
    } catch (error) {
      console.log(error);
    }

  }, []) 

  React.useEffect(() => {
    if (env_type === "local") {
      localLogin();
    }
  },[])

  React.useMemo(() => {
    if (env_type !== "local") {
      const { token, refresh_token, id } = props.customProps.auth_token;

      api.defaults.headers.common["Authorization"] = `bearer ${token}`;
      api.defaults.headers.common["refresh_token"] = refresh_token; 
    }

},[])

  return (
    <>
      <MenuProvider>
        <UserProvider>
          <Globals />
          <SideBar menus={menus} />
        </UserProvider>
      </MenuProvider>
    </>
  );
}

import styled, { keyframes } from "styled-components";
import { ISideBarProps } from "../SideBar/Sidebar.structure";

const Smooth = keyframes`
  0% {
    margin-bottom: 1.3rem;
  }

  90% {
    margin-bottom: 1.3rem;
  }

  100% {
    margin-bottom: 0rem;
  }
`;

export const CollapsebleMenu = styled.div<ISideBarProps>`
  display: flex;
  background-color: var(--gray4);
  flex-direction: column;
  padding: 1rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
`;

export const CollapsebleMenuTitle = styled.div<ISideBarProps>`
  display: flex;
  flex-direction: row;
  color: ${(props) => (props.isActive ? "var(--primary)" : "var(--gray1)")};
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-bottom: ${(props) => (props.isOpen ? "0rem" : "1.3rem")};
  animation: ${(props) => (props.isOpen ? Smooth : "none")} 0.2s ease-in-out;

  div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    svg {
      min-width: 1.5rem;
      margin: auto 0.4rem auto 0;
      transition: all 0.2s ease-in-out;
    }
  }

  span {
    transition: all 0.2s ease-in-out;
    visibility: ${(props) => (props.collapse ? "hidden" : "visible")};
    margin-right: ${(props) => (props.collapse ? "-10rem" : "0")};
    margin-left: ${(props) => (props.collapse ? "4rem" : "0rem")};
  }
`;

export const CollapsebleMenuItems = styled.div<ISideBarProps>`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.isOpen ? "0rem" : 'fit-content')};
  transition: height 0.2s ease-in-out;
  overflow: hidden;
  white-space: nowrap;
  gap: 0.7rem;
`;

export const CollapsebleSubMenuTitle = styled.div<ISideBarProps>`
  color: var(--gray1);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: ${(props) =>
    props.collapse ? "0.25rem 0" : "0.25rem 0.5rem 0.25rem 1rem"};
  white-space: normal;

  p {
    margin: 0;
    transition: all 0.2s ease-in-out;
    margin-left: ${(props) => (props.collapse ? "4rem" : "0rem")};
    color: ${(props) => (props.isActive ? "var(--primary)" : "var(--gray1)")};
  }
`;

import React from "react";
import { IProps } from "./MenuProvider.structure";
import Cookies from 'js-cookie'
import PermissionsService from '../service/Permissions'
import { IPermissions, IUserContext } from "./UserProvider.structure";


export const UserContext = React.createContext({} as IUserContext);

const UserProvider: React.FC<IProps> = ({ children }) => {
  const [userPermissions, setUserPermissions] = React.useState<string[]>([]);

  async function getUserPermissions(id:string) {
    const response = await PermissionsService.getUserPermissions(id);

    if(!response)return;

    permissionFormatter(response);


  }

  function permissionFormatter(permissions:IPermissions[]) {
    const formattedPermissions = permissions.map(permission => {
      if(userPermissions.includes(permission.claim)) return;
      setUserPermissions(prevValue => [...prevValue, permission.claim])

      return
    })
    return formattedPermissions;
  }

  React.useEffect(() => {
    const userId = Cookies.get('id');
    if(userId) {
      getUserPermissions(userId)
    }
  },[])

  const value = React.useMemo(
    () => ({
      userPermissions,
      setUserPermissions,
    }),
    [userPermissions]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;

import React from "react";
import {
  Bottom,
  Container,
  HamburguerContainer,
  Stuffing,
  Top,
} from "./styles";

const Hamburguer = ({
  collapse,
  setIsOpen,
}: {
  collapse: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <Container
      onClick={() => {
        setIsOpen(!collapse);
      }}
      data-testid="hamburguer-button"
    >
      <HamburguerContainer>
        <Top active={!collapse}></Top>
        <Stuffing active={!collapse}></Stuffing>
        <Bottom active={!collapse}></Bottom>
      </HamburguerContainer>
    </Container>
  );
};

export default Hamburguer;

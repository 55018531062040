import MenuButton from "../MenuButton";
import { Container, ContainerMenus, LoggoutButton } from "./styles";
import React from "react";
import { FaShareSquare } from "react-icons/fa";
import Hamburguer from "../Hamburguer";
import { IMenusList } from "./Sidebar.structure";
import Cookie from 'js-cookie'
import MenuGroups from "../MenuGroups";
import { MenuContext } from "../../context/MenuProvider";
import { ByMenu } from "../../utils/LinksDictionary";
import { UserContext } from "../../context/UserProvider";
import jwtDecode, {JwtPayload} from "jwt-decode";

const SideBar = ({ menus }: { menus: IMenusList[] }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [activeMenu, setActiveMenu] = React.useState<number>(0);
  const { selectedMenu, setSelectedMenu } = React.useContext(MenuContext);
  const {userPermissions} = React.useContext(UserContext)

  const logout = () => {
    Cookie.remove("hub_auth");
    Cookie.remove("id");
    Cookie.remove("token");
    (window.location.href = "/"), true;
    return;
  };

  function checkPermissionClaim(claim: string) {
    return userPermissions?.some((permission) => {
      const [permissionClaim] = permission.split('.');
      return permissionClaim === claim;
    });
  }

  function checkToken() {
    const token = Cookie.get("token");
    if (!token) {
      logout();
      return;
    }

    const tokenDecoded = jwtDecode<JwtPayload>(token);
    const currentTime = new Date().getTime() / 1000;

    if (tokenDecoded.exp < currentTime) {
      logout();
    }
  }

  const Menus = React.useMemo(() => {
    return menus?.map((item, index) => {
      const {titleMenu, iconMenu, related_flag} = item;
      const hasPermission = checkPermissionClaim(related_flag[0]);
      if (!hasPermission) return;

      return (
        <div
          key={index}
          role="presentation"
          onClick={() => {
            setSelectedMenu(ByMenu[titleMenu]);
            setActiveMenu(index);
          }}
          data-testid={`menu-${index}`}
        >
          <MenuButton
            isActive={titleMenu === selectedMenu?.menu}
            title={titleMenu}
            iconMenu={iconMenu}
            collapse={isOpen}
          />
        </div>
      );
    });
  }, [menus, isOpen, activeMenu, selectedMenu,userPermissions]);

  const menuGroup = menus?.find(
    (menu) => menu.titleMenu === selectedMenu?.menu
  );

  React.useEffect(() => {
    const tokenValidation = setInterval(() => {
      checkToken();
    }, 1000 * 60);

    return () => clearInterval(tokenValidation);
  },[]);

  return (
    <Container data-testid="sidebar">
      <ContainerMenus collapse={isOpen}>
        <div>
          <Hamburguer collapse={isOpen} setIsOpen={setIsOpen} />
          {Menus}
        </div>
        <LoggoutButton onClick={logout} isOpen={isOpen} collapse={isOpen}>
          <FaShareSquare />
          <span>Logout</span>
        </LoggoutButton>
      </ContainerMenus>

      <MenuGroups groups={menuGroup?.groups} collapse={isOpen} />
    </Container>
  );
};
export default SideBar;

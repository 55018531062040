import axios from "axios";
import Cookie from 'js-cookie'

const base_url = process.env.SIDEMENU_URL;

const api = axios.create({
  baseURL: base_url,
})

api.interceptors.request.use( async config => {
  const token = Cookie.get('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
})

export default api;

export const LinksDictionary = {
  DIMP: {
    path: "/hub/fiscal/accessoryObligations/dimp",
    title: "DIMP",
    group: "Fiscal",
  },
  Alertas: {
    path: "/hub/compliance-alerts",
    title: "Alertas",
    group: "Compliance",
  },
  "Criar perfil": {
    path: "/hub/create-users",
    title: "Criar perfil",
    group: "ADM",
  },
  "Criar grupo de permissões": {
    path: "/hub/create-group",
    title: "Criar grupo de permissões",
    group: "ADM",
  },
  "Gerenciar permissões": {
    path: "/hub/assign-group",
    title: "Gerenciar permissões",
    group: "ADM",
  },
  "Gerenciar perfis": {
    path: "/hub/manage-users",
    title: "Gerenciar permissões",
    group: "ADM",
  },
};

export const ActiveMenu = {
  "/hub/create-users": {
    menu: "ADM",
  },
  "/hub/manage-groups": {
    menu: "ADM",
  },
  "/hub/manage-levels": {
    menu: "ADM",
  },
  "/hub/manage-users": {
    menu: "ADM",
  },
  "/hub/compliance-alerts": {
    menu: "Compliance",
  },
  "/hub/fiscal/accessoryObligations/dimp": {
    menu: "Fiscal",
  },
};

export const ByMenu = {
  ADM: {
    menu: "ADM",
  },
  Fiscal: {
    menu: "Fiscal",
  },
  Compliance: {
    menu: "Compliance",
  },
};

import styled from "styled-components";
import { ISideBarProps } from "./Sidebar.structure";

export const Container = styled.div`
  display: flex;
`;

export const ContainerMenus = styled.div<ISideBarProps>`
  height: 100vh;
  background-color: var(--gray4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => (props.collapse ? "50px" : "110px")};
  transition: width 0.3s ease-in-out;
`;

export const LoggoutButton = styled.div<ISideBarProps>`
  background-color: var(--gray6);
  color: var(--gray1);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 3rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  span {
    overflow: hidden;
  }

  svg {
    min-width: 1.5rem;
    margin-right: ${(props) => (!props.isOpen ? "4px" : "0.9rem")};
    margin-left: 18px;
    transition: all 0.3s ease-in-out;
  }
`;
